<template>
    <navigation-mobile-pos />

    <div id="mobilepos" class="p-1">
    </div>
</template>

<script>
// mixins
import PosBootstrapHelper from '../../mixins/pos-bootstrap.helper.js';

// components
import NavigationMobilePos from '../../components/NavigationMobilePos'

// modals
export default {
    name: 'MobilePos',
    mixins: [PosBootstrapHelper],
    components: {
        NavigationMobilePos,
    },
}


</script>
